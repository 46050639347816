<template>
    <v-row no-gutters class="py-1">
        <v-col cols="12" class="d-flex flex-row">
            <template v-if="dealStageHistory.length < 7">
                <template v-for="stageHistory in dealStageHistory">
                    <DealStageNode
                        :stage="stageHistory.stage"
                        :stage-date="stageHistory.date"
                        :node-type="nodeType(stageHistory.stage)"
                        :key="stageHistory.id"
                    />
                    <DealStageConnector :days-to-next-stage="stageHistory.daysToNextStage" v-if="stageHistory.stage != 'Act'"/>
                </template>
            </template>
            <template v-else>
                <div class="d-flex flex-column">
                    <div class="d-flex flex-row">
                        <template v-for="(stageHistory, index) in dealStageHistory.slice(0,6)">
                            <DealStageNode
                                :stage="stageHistory.stage"
                                :stage-date="stageHistory.date"
                                :node-type="nodeType(stageHistory.stage)"
                                :key="stageHistory.id"
                            />
                            <DealStageConnector :days-to-next-stage="stageHistory.daysToNextStage" v-if="stageHistory.stage != 'Act' && index != 5"/>
                        </template>
                    </div>
                    <DealStageLongConnector :days-to-next-stage="dealStageHistory[5]['daysToNextStage']"/>
                    <div class="d-flex flex-row" style="margin-top: 5px">
                        <template v-for="stageHistory in dealStageHistory.slice(6)">
                            <DealStageNode
                                :stage="stageHistory.stage"
                                :stage-date="stageHistory.date"
                                :node-type="nodeType(stageHistory.stage)"
                                :key="stageHistory.id"
                            />
                            <DealStageConnector :days-to-next-stage="stageHistory.daysToNextStage" v-if="stageHistory.stage != 'Act'"/>
                        </template>
                    </div>
                </div>
            </template>
        </v-col>
    </v-row>
</template>

<script>
import {log} from "Helpers/helpers";
import DealStageNode from "Components/Appic/DealStageNode";
import DealStageConnector from "Components/Appic/DealStageConnector";
import DealStageLongConnector from "Components/Appic/DealStageLongConnector";

export default {
    name: "DealStageHistory",
    components: {DealStageLongConnector, DealStageConnector, DealStageNode},
    props: ["dealStageHistory"],
    methods: {
        stageLabel(stage) {

        },
        nodeType(stage) {
            if(stage == 'S') return 'start'
            if(stage == 'Act') return 'end'
            return null
        }
    },
    mounted() {
        // log(this.dealStageHistory)
    }
}
</script>

<style scoped>
</style>