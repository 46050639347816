<template>
    <div class="long-connector d-flex flex-row align-center">
        <span class="icon mdi mdi-arrow-down"></span>
        <div class="line flex-grow-1 d-flex flex-row align-center justify-center"><span v-if="daysToNextStage != null">{{ daysToNextStage + 'd'}}</span></div>
        <div style="width: 3px !important; height: 27px; background-color: black; margin-right: 30px; margin-top: -45px"></div>
    </div>
</template>

<script>
export default {
    name: "DealStageLongConnector",
    props: ["daysToNextStage"]
}
</script>

<style scoped>
.long-connector {
    min-width: 100px !important;
}
.long-connector .line{
    height: 3px;
    background-color: black;
    margin-bottom: 15px;
    margin-left: -21px;
    margin-right: -3px;
}
.long-connector .icon {
    color:black !important;
    font-size: 3em;
    line-height: 1em;
    margin-right: 0px !important;
    margin-left: -5px !important;
    margin-bottom: -15px;
}
.long-connector .line span{
    font-size: 1.3em;
    background-color: white;
    padding-left: 2px;
    padding-right: 2px;
    margin-left: 1em;
}
</style>